import { ENVS } from "@/configs/Configs.env";
import { Connection } from "@solana/web3.js";

export const ONE_SECOND = 1000;

export const FORMAT_TIMESTAMP = "YYYY/MM/DD";
export const FORMAT_DATETIMESTAMP = "MM-DD HH:MM";
export const FORMAT_DATETIMESTAMP_FULL = "MMM DD, YYYY ";

export const PRIVATE_KEY_B58 = "private_key_b58";

export enum ERROR_WALLET {
  UninitializedWalletAdapterError = "UninitializedWalletAdapterError",
  Method_Notfound = "method not found",
}

export const ConnectionSol = new Connection(
  ENVS.REACT_APP_SOL_RPC,
  "confirmed"
);

export const gateProvider = window?.gatewallet?.solana;
